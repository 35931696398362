import { Component, OnInit } from '@angular/core';
import { register } from 'swiper/element/bundle';

import { DataService } from './utils/services/shared/data.service';
import { UserService } from './utils/services/user.service';
import { ListenerService } from './utils/services/shared/listener.service';
import { SocketService } from './utils/services/shared/socket.service';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent implements OnInit  {

  constructor(private listenerService: ListenerService, private dataService: DataService, private socketService: SocketService, private userService: UserService) {}

  ionViewWillEnter() {
    try {

      //console.log('AppComponent ionViewWillEnter');

      return;

    } catch (error) {
      console.log(error);
      return
    }
  }

  ionViewDidEnter() {
    try {

      //console.log('AppComponent ionViewDidEnter');

      return;

    } catch (error) {
      console.log(error);
      return
    }
  }

  async ngOnInit() {
    try {

      return;

    } catch (error) {
      console.log(error);
      return
    }
  }

}
