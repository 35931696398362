
import { Injectable } from '@angular/core';
import { AlertController, PopoverController, ToastController } from '@ionic/angular';
import { Location } from "@angular/common";
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';

var random = require('random-string-generator');

import { DataService } from './data.service';


@Injectable({ providedIn: 'root' })

export class HelperService {

  constructor(private popoverController: PopoverController, public loadingController: LoadingController, private alertController: AlertController, private dataService: DataService, private location: Location, private toastController: ToastController, private router: Router) {}

  async showLoading(message: string) {
    try {
    
      this.dataService.setProperty('loading', await this.loadingController.create({
        message: message
      }));

      await this.dataService.getProperty('loading').present();

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  async hideLoading() {
    try {

      if (this.dataService.getProperty('loading')) {
        await this.dataService.getProperty('loading').dismiss();
      }

      return;

    } catch (error) {
      return;
    }
  }

  generateRandomString(length: number) {
    return random(length, 'uppernumeric');
  }

  processTypeDisplay(accounts: any, type: string) {
    try {

      var display = '';

      if (!accounts) {
        return;
      }

      for (let i = 0; i < accounts.length; i++) {

        if (!accounts[i].biography || (accounts[i].biography && (!accounts[i].biography.age || !accounts[i].biography.sex))) {

          display += 'N/A';
          
        } else {

          const age = accounts[i].biography.age;
          const sex = accounts[i].biography.sex;

          switch (sex) {
            case 'Male':
              display += `${age} M`;
              break;
            case 'Female':
              display += `${age} F`;
              break;
            case 'Non-Binary':
              display += `${age} NB`;
              break;
          }
        }

        if (i < accounts.length - 1) {
          display += ' / ';
        }
      }

      if (type === 'shared') {
        if (accounts.length === 1) {
          display += ' / Pending';
        }
      }

      return display;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  async tour(step: string, next?: string) {
    try {

      if (!next) {
        await this.sleep(500);
      }

      this.dataService.setProperty('onboarding', { step: step });

      if (next) {
        await this.sleep(500);
      }

      if (next) {
        this.goTo(next);
      }

      return;

    } catch (error) {
      console.log('tour', error);
      return;
    }
  }

  async goBack() {
    try {

      const currentTab = this.dataService.getProperty('tab');

      const newTab = {
        active: currentTab.previous,
        previous: currentTab.active
      };

      this.dataService.setProperty('tab', newTab);

      this.location.back();

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  async goTo(tab: string) {
    try {

      const currentTab = this.dataService.getProperty('tab');

      const newTab = {
        active: tab,
        previous: currentTab ? currentTab.active : tab
      };

      this.dataService.setProperty('tab', newTab);

      this.router.navigate(['tabs/' + tab]);

      return;

    } catch (error) {
      console.log(error);
      return;''
    }
  }

  async sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  isProfileValid() {
    try {

      if (this.dataService.getProperty('accountProfile').type === 'shared' && this.dataService.getProperty('accountProfile').accounts.length === 1) {
        return false;
      }

      return true;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  async presentNoticeAlert(header = null, message = null) {

    if (!header) {
      header = 'Are you sure?';
    }
    
    const confirmText = 'Ok';

    const alert = await this.alertController.create({
      header: header,
      message: message,
      backdropDismiss: false,
      buttons: [
        {
          text: confirmText,
          handler: () => {
            return true;
          }
        }
      ]
    });

    await alert.present();

    return await alert.onDidDismiss();

  }

  async presentConfirmationAlert(header = null, message = null, type?: string) {

    if (!header) {
      header = 'Are you sure?';
    }

    const confirmText = 'Yes';
    const cancelText = 'No';

    const alert = await this.alertController.create({
      header: header,
      message: message,
      backdropDismiss: false,
      buttons: [
        {
          text: confirmText,
          handler: () => {
            return true;
          }
        },
        {
          text: cancelText,
          role: 'cancel',
          handler: () => {
            return false;
          }
        }
      ]
    });

    await alert.present();

    return await alert.onDidDismiss();

  }

  async presentToast(msg, isSuccess: boolean = false) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      color: isSuccess ? 'success' : 'primary',
      // cssClass: 'custom-toast',
      buttons: [],
    });

    await toast.present();
  }
}
